<template>
	<div>
		<agent-filters
			:name-filter.sync="nameFilter"
			:code-filter.sync="codeFilter"
			:status-filter.sync="statusFilter"
			:status-options="statusOptions"
		/>
		<b-card
			no-body
		>
			<b-table
				ref="refAgentTable"
				class="position-relative table-white-space mb-0"
				:items="fetchAgents"
				responsive
				:fields="tableColumns"
				primary-key="index"
				show-empty
				empty-text="No matching records found"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalAgent"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Add Bonus Modal -->
		<!-- <add-bonus-modal/> -->
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal, BAlert,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, onUnmounted, computed } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import agentStoreModule from '@/views/agent/agentStoreModule'
import AgentFilters from './AgentFilters.vue'

export default {
	components: {
		AgentFilters,
		// AddBonusModal: () => import('./AddBonusModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
        BAlert,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			roleId: null,
			content: null,
		}
	},
	methods: {
		showAddBonusModal() {
			this.$bvModal.show('modal-add-bonus')
		},
	},
	setup() {
        const AGENT_MODULE_NAME = 'agent'
		if (!store.hasModule(AGENT_MODULE_NAME)) store.registerModule(AGENT_MODULE_NAME, agentStoreModule)
		onUnmounted(() => {
			if (store.hasModule(AGENT_MODULE_NAME)) store.unregisterModule(AGENT_MODULE_NAME)
		})
        const dataDemos = [
            { username: 'usertestthb001', product: 'Club 8', status: 1, rebate_amount: 0, rebate_ratio: 0.6, bonus_rollover: 0, eligible_stake: 12330,	currency: 'VND', rebate_date: '04-21-2022 07:00:00' },
            { username: 'usertestthb001', product: 'Club K', status: 1, rebate_amount: 0, rebate_ratio: 0.4, bonus_rollover: 0, eligible_stake: 2194,	currency: 'VND', rebate_date: '04-21-2022 07:00:00' },
        ]
		const agentLists = ref(dataDemos)
		const perPage = ref(25)
		const totalAgent = ref(0)
		const currentPage = ref(1)
		const nameFilter = ref(null)
		const codeFilter = ref(null)
		const statusFilter = ref(null)
		const refAgentTable = ref(null)
		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'name', label: 'Name' },
			{ key: 'code', label: 'Code' },
			{ key: 'status', label: 'Status' },
		]
		
		const refetchData = () => {
			refAgentTable.value.refresh()
		}

		watch([currentPage, nameFilter, codeFilter, statusFilter], () => {
			refetchData()
		})

        const fetchAgents = (ctx, callback) => {
            store
                .dispatch('agent/fetchAgents', {
                    status: statusFilter.value,
                    page: currentPage.value,
                })
                .then(response => {
                    if (response.data.code == '00') {
                        callback(response.data.data)
                        totalAgent.value = response.data.count
                        perPage.value = currentPage.value == 1 ? response.data.data.length : 25
                    }
                })
        }

        const dataMeta = computed(() => {
			const localItemsCount = refAgentTable.value ? refAgentTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalAgent.value,
			}
		})

		const statusOptions = [
			{ label: 'Active', value: 1 },
			{ label: 'Inactive', value: 2 },
		]

		const resolveStatus = status => {
			if (status == 1) return { label: 'Active', variant: 'primary' }
			if (status == 2) return { label: 'Inactive', variant: 'danger' }
			return { label: 'None', variant: 'secondary' }
		}
		return {
            fetchAgents,
			tableColumns,
			perPage,
			currentPage,
			totalAgent,
			dataMeta,
			refAgentTable,

			agentLists,
			refetchData,

			nameFilter,
			codeFilter,
			statusFilter,
			statusOptions,
			formatDateTime,
            resolveStatus,
		}
	},
}
</script>